<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <form @submit.prevent="findOrder">
            <div class="card">
              <div class="card-body">
                <p>&nbsp;</p>
                <div class="media">
                  <vue-feather class="m-r-30" type="search"></vue-feather>
                  <div class="media-body">
                    <h6 class="f-w-500"> {{this.currentLanguage ==='en'?"Find order":"ค้นหาคำสั่งซื้อ"}}</h6>
                    <div class="row">
                      <div class="mb-3 col-sm-6">
                        <label for="moblieNo"
                          ><h3>
                            {{this.currentLanguage ==='en'?"Mobile number":"หมายเลขโทรศัพท์"}}
                            
                          </h3>
                          
                        </label>
                        <input
                          
                          maxlength="10"
                          minlength="10"
                          type="text"
                          class="form-control"
                          id="moblieNo"
                          placeholder="หมายเลขโทรศัพท์"
                          v-model="mobile"
                        />
                      </div>
                      <!-- <div class="mb-3 col-sm-6">
                        <label for="email"
                          ><h3>อีเมลล์ <span style="font-size: small; background: inherit;">(ถ้ามี)</span> </h3>
                        </label>
                        <input
                          
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="อีเมลล์"
                          v-model="email"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row">&nbsp;</div>
                <div class="row">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      style="cursor: pointer"
                      class="btn btn-primary btn-lg"
                    >
                    <vue-feather type="loader" size="20" animation="spin" v-if="this.is_searching ==='true'"></vue-feather> {{this.currentLanguage ==='en'?"Search":"ค้นหา"}}
                    </button>
                    &nbsp;
                    <button
                      style="cursor: pointer"
                      @click.prevent="clear_result"
                      type="reset"
                      class="btn btn-secondary btn-lg"
                    >
                      {{this.currentLanguage ==='en'?"Cancel":"ยกเลิก"}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-1"></div>
      </div>

      <div class="row" v-if="this.start_search === 'true'">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <div class="card">
            <div class="card-body">
              <div v-if="this.is_searching === 'true'">
                <div class="d-flex justify-content-center">
                  <img width="60" src="../../assets/images/running_loading.svg" />
                </div>
              </div>

              <div
                v-if="!this.resultList.length && this.is_searching === 'false'"
              >
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order date":"วันที่สั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order":"คำสั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Event":"กิจกรรม"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Status":"สถานะ"}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" style="text-align: center">
                        {{this.currentLanguage ==='en'?"order not found":"ไม่พบข้อมูลคำสั่งซื้อ"}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="this.resultList.length" class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order date":"วันที่สั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order":"คำสั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Event":"กิจกรรม"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Status":"สถานะ"}}</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(src, index) in this.resultList" :key="index">
                      <td>{{ src.create_date }}</td>
                      <td >
                        <a
                          target="_blank"
                          :href="
                            '/download/' + src.order_id
                          "
                          ><b><u><vue-feather size="15" type="image"></vue-feather>{{this.currentLanguage ==='en'?"Open gallery":"เปิด ลิงค์สำหรับโหลดรูปภาพ"}}</u></b></a
                        >
                      </td>
                      <td>{{ src.title }}</td>
                      <td v-if="src.status === 'INITIAL'">
                        <font style="color: red;">{{this.currentLanguage ==='en'?"Waiting for payment":"รอชำระเงิน"}}</font>
                      </td>
                      <td v-if="src.status === 'PAID'">
                        <font style="color: green;">{{this.currentLanguage ==='en'?"Completed":"สำเร็จ"}}</font>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'
export default {
  data() {
    return {
      email: "",
      mobile: "",
      resultList: {},
      status: "",
      is_searching: "false",
      start_search: "false",
      currentLanguage: 'th'
    };
  },
  setup() {
    useHead({
      title: computed(() => `ค้นหาคำสั่งซื้อ ค้นหารูปความประทับใจด้วยใบหน้า | RUNNING.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `ค้นหาคำสั่งซื้อ ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`),
        },
        {
          name: `keywords`,
          content: computed(() => `ค้นหาคำสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,running,runninghub,facesearch`),
        }
        ],
     
    })
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  methods: {
    async findOrder() {

      if(this.mobile === '' && this.email === ''){
        return
      }
      
      this.start_search = "true";
      this.resultList = {};
      this.is_searching = "true";

      
      const data = {
        tk: process.env.VUE_APP_FN_TO_BK_SECRET_KEY,
        mobile: this.mobile,
        email: this.email,
      };
      let result = await axios.post("/v1/order/find-order", data);
      this.status = result.data.status;
      if (result.data.status === "success") {
        this.resultList = result.data.body;
      }
      this.is_searching = "false";
    },
    async clear_result() {
      this.start_search = "false";
      this.resultList = {};
      this.mobile = "";
      this.email = "";
    },
  },
};
</script>